header{
    display: flex; 
    align-items: flex-end;
    background-color: #313E50;
    padding: 10px;
}
.logo {
    height: 100px;
    padding-left: 35px;
}

.logo img {
    align-self: flex-start;
    height: 100%;
}